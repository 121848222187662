export const theme = {
  zeroColor: "rgba(43,42,41,255)",
  firstColor: "gray",
  secondColor: "white",
  thirdColor: "orange",
  fourColor: "rgba(219, 229, 20, 0.776)",
  fiveColor: "black",
  sixColor: "rgba(218, 229, 229, 1)",
  sevethColor: "rgba(218, 229, 229, 0.5)",
};
